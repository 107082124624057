import {Component, Input, OnInit} from '@angular/core';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss']
})
export class GeneratorComponent implements OnInit {

  @Input() activitate: any = null;

  value = '';
  error = null;

  constructor() { }

  ngOnInit() {
  }

  validator() {
    return !!this.activitate.generatorList[this.value];
  }

  onClick() {
    this.error = null;

    if (!this.validator()) {
      this.error = 'Nu sunt conditii de generare!';
      return;
    }
    const indicativ = this.value;
    const nume = this.activitate.generatorList[this.value];

    const doc = new jsPDF(this.activitate.orientation);
    doc.setTextColor(this.activitate.textProps.r, this.activitate.textProps.g, this.activitate.textProps.b);
    doc.setFontSize(this.activitate.textProps.fontSize);

    const img = new Image();
    img.src = this.activitate.generatorDiploma;
    doc.addImage(img, 'png', 0, 0, this.activitate.img.wigth, this.activitate.img.height);

    doc.text(indicativ, this.activitate.texts.indicativ.x, this.activitate.texts.indicativ.y, );
    doc.text(nume, this.activitate.texts.nume.x, this.activitate.texts.nume.y, );


    doc.save('Diploma_' + indicativ);
  }

  onChangeInput($event) {
    this.value = $event.target.value;
  }
}
