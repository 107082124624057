import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(
    router: Router,
    location: Location
  ) {
    router.events.subscribe(val => {
      this.aboutUsLabelColor = this.aboutUsDropdownPages.includes(location.path());
      this.membriiLabelColor = this.membriiDropdownPages.includes(location.path());
      this.activitatiLabelColor = this.activitatiiDropdownPages.includes(location.path());
    });
  }
  aboutUsDropdownPages = ['/despre-noi', '/file-de-istorie', '/stadionul-din-eter', '/contact'];
  aboutUsDropdown = false;
  aboutUsLabelColor = false;

  membriiDropdownPages = ['/cluburi', '/radioamatori'];
  membriiDropdown = false;
  membriiLabelColor = false;

  activitatiiDropdownPages = ['/download-log', '/indicative-speciale', '/concursuri', '/alte-activitati'];
  activitatiDropdown = false;
  activitatiLabelColor = false;

  toggleAboutUsDropdown() {
    this.aboutUsDropdown = !this.aboutUsDropdown;
  }

  toggleMembriiDropdown() {
    this.membriiDropdown = !this.membriiDropdown;
  }

  toggleActivitatiDropdown() {
    this.activitatiDropdown = !this.activitatiDropdown;
  }

  ngOnInit() {
  }

}
