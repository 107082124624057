import { Component, OnInit } from '@angular/core';
import {activitati} from "./activitati_data";

@Component({
  selector: 'app-activitati',
  templateUrl: './infocorona.component.html',
  styleUrls: ['./infocorona.component.scss']
})
export class InfocoronaComponent implements OnInit {

  activities = activitati;

  constructor() { }

  downlodFile(type = 1) {
    if (type === 1) {
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = 'https://www.albadxradio.ro/assets/program/LogON.zip';
      // link.href = 'https://www.albadxradio.ro/assets/images/activitati/0/0.jpg';
      link.download = 'LogON.zip';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    if (type === 2) {
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = 'https://www.albadxradio.ro/assets/program/LogOFF.zip';
      link.download = 'LogOFF.zip';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  ngOnInit() {
  }

}
