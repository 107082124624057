import { Component, OnInit } from '@angular/core';
import {activitati} from "./activitati_data";

@Component({
  selector: 'app-activitati',
  templateUrl: './indicative.component.html',
  styleUrls: ['./indicative.component.scss']
})
export class IndicativeComponent implements OnInit {

  activities = activitati;

  constructor() { }

  ngOnInit() {
  }

}
