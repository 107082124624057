import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomepageComponent} from './homepage/homepage.component';
import {AboutusComponent} from './aboutus/aboutus.component';
import {ClubsComponent} from './clubs/clubs.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {MembriiComponent} from './membrii/membrii.component';
import {ConcursuriComponent} from './concursuri/concursuri.component';
import {ActivitatiComponent} from './activitati/activitati.component';
import {CentenarComponent} from './centenar/centenar.component';
import {FileDeIstorieComponent} from "./file-de-istorie/file-de-istorie.component";
import {StadionulDinEterComponent} from "./stadionul-din-eter/stadionul-din-eter.component";
import {ContactComponent} from "./contact/contact.component";
import {PhotoAlbumComponent} from "./photo-album/photo-album.component";
import {PrezentaComponent} from "./prezenta/prezenta.component";
import {LinksComponent} from "./links/links.component";
import {InfocoronaComponent} from './info-corona/infocorona.component';
import {IndicativeComponent} from './indicative/indicative.component';
import {Teste} from './teste/teste';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'cluburi', component: ClubsComponent },
  { path: 'radioamatori', component: MembriiComponent},
  { path: 'file-de-istorie', component: FileDeIstorieComponent},
  { path: 'stadionul-din-eter', component: StadionulDinEterComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'album-foto', component: PhotoAlbumComponent},
  { path: 'concursuri', component: ConcursuriComponent},
  { path: 'download-log', component: InfocoronaComponent},
  { path: 'indicative-speciale', component: IndicativeComponent},
  { path: 'centenar', component: CentenarComponent},
  { path: 'despre-noi', component: AboutusComponent },
  { path: 'teste', component: Teste },
  { path: 'prezenta', component: PrezentaComponent },
  { path: 'linkuri-utile', component: LinksComponent },
  { path: 'alte-activitati', component: ActivitatiComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
