export const activitati = [
  {
    id: -1,
    title: 'YR100REX - Centenarul Încoronării Regelui Ferdinand - 2022',
    content: 'Anul acesta, la 15 Octombrie s-au împlinit 100 de ani de la încoronarea Regelui Ferdinand, ca rege al Romaniei Mari, în Catedrala Încoronarii, din Alba Iulia.\n' +
      'La adunarea generală a asociației noastre a fost făcută propunerea sărbătoririi prin radio a Centenarului Încoronării. Au inceput pregatirile.\n' +
      'Am obținut indicativul YR100REX, valabil în perioada 05 - 15 octombrie 2022, pe care l-am folosit in această perioadă în toate benzile și modurile de lucru.\n' +
      'Am înscris indicativul pe site-ul qrz.com și l-am personalizat.\n' +
      'Am înscris indicativul pe QSL, Bronze și l-am autentificat.\n' +
      'Am tipărit un QSL care să reflecte sinteza evenimentului.\n' +
      'Am proiectat și o diplomă aniversară care va putea fi descărcată electronic de pe site-ul asociației noastre de către participanții la concurs.\n' +
      'Am organizat un concurs cu un regulament interesant, la care au participat 38 de radioamatori. Rezultatele le-am publicat împreună cu alte detalii și actualizări pe site-ul nostru www.albadxradio.ro.\n' +
      'Au participat efectiv la desfasurarea acțiunilor: YO5DDD, YO5YM, YO5OB, YO5CFI, YO5OHT, YO5IB, YO5OKM, YO5OHC, YO3JW – trafic radio și YO5PUR, YO5BFJ – organizare.\n' +
      'Din păcate partea de colaborare cu Primăria Alba Iulia a eșuat.\n' +
      '',
    photos: ['assets/images/activitati/16/1.jpg', ]
  },
  {
    id: 0,
    title: 'YQØU - Ziua Națională a României - 2021',
    content: '\tS-au împlinit în 2021, 31 de ani de existență a indicativului care celebrează Ziua Națională a României. Fie că a fost la început YO0U, apoi YQ0U, YQ90U sau YR100U, din anul 1990, a transmis în eter, întregii lumi, bucuria sărbătorii României. Au lucrat la acest proiect toți membrii asociației și am fost sprijinți de Consiliul Județean Alba, Direcția pentru tineret și sport Alba și Cercul Miltar Alba Iulia, precum și de numeroși radioamatori din țară. Am primit aprecieri și mulțumiri din toată lumea pentru această acțiune și putem spune, cu mândrie, că exemplul nostru a fost copiat și de alte entități radioamatoricești care își sărbătoresc asfel zilele naționale.\n' +
      '\tAnul acesta am realizat 1927 de legături, în cele 3 moduri de lucru (CW, FONE și DIGI) prin bunavoința și efortul următorilor asociați,YO5DDD, YO5OKM, YO5OHT, YO5YM, YO5OB, YO5IB și YO5BFJ, cărora le mulțumim public aici. Primii 3 clasați din această echipă vor fi recompensați de către asociație.\n' +
      '\tVă urăm Crăciun fericit, Sărbători frumoase și un an 2022 cu sănătate și mai bun. La mulți ani tuturor!\n',
    photos: ['assets/images/activitati/11/0.jpg', 'assets/images/activitati/11/1.jpg',]
  },
  {
    id: 1,
    title: 'YQØU - Ziua Națională a României',
    content: 'În anul 1990, după decretarea zilei de 1 Decembrie ca Zi Națională a României, echipa de la radioclubul Casei Armatei din Alba Iulia, cu prilejul sărbătorilor organizate la începutul lunii decembrie în municipiul nostru, a solicitat indicativul special YOØU pentru a sărbători pe calea undelor radio marele eveniment. Indicativul a devenit astfel o tradiție, iar după 7 ani, conform unei hotărâri a ANCOM, indicativul a fost schimbat în YQ0U. Începând din anul 1991 clubul a organizat și concursul CUPA 1 DECEMBRIE.\n' +
      '\tÎn anul 2008 indicativul a fost înlocuit cu YQ90U semnificand cei 90 ani de la Marea Unire, iar in anul 2018 a devenit YR100U.\n' +
      '\tCu aceste indicative au fost realizate anual cca. 2000 - 2500 de qso-uri care au fost confirmate cu QSL-uri tiparite (cu indicativul YR100U s-au realizat 12.000 qso-uri).\n' +
      '\tÎn anul 2002 activitățile au fost preluate de A.J.R Alba care a instituit și diploma 1 Decembrie 1918.',
    photos: ['assets/images/activitati/11/0.jpg', 'assets/images/activitati/11/1.jpg', 'assets/images/activitati/11/2.jpg', 'assets/images/activitati/11/3.jpg', 'assets/images/activitati/11/4.jpg', 'assets/images/activitati/11/5.jpg', 'assets/images/activitati/11/6.jpg']
  },
  {
    id: 2,
    title: 'YPØLB - Festivalul Lucian Blaga\n',
    content: 'A fost inițiativa radioamatorilor din județul Alba, cu ocazia primului Festival Internațional Lucian Blaga desfășurat la Sebeș în anul 2007. Prin acest proiect s-a dorit promovarea unei personalități marcante a culturii naționale și internaționale Lucian Blaga, eminent poet, profesor universitar, jurnalist, filozof și diplomat, al cărui caracter vertical s-a opus vicisitudinilor istoriei. A fost membru al Academiei Române.\n' +
      'Pentru participarea la acest eveniment se acordă și o diplomă.\n' +
      'Anual, la începutul lunii mai este organizat concursul Lucian Blaga, concurs în care fiecare participant transmite obligatoriu, pe lângă codul de concurs, un titlu din creația acestei mari personalități.\n',
    photos: ['assets/images/activitati/11/7.jpg']
  },
  {
    id: 3,
    title: 'YRØUCRR',
    content: 'Ne bucură permanentizarea colaborării cu Uniunea Culturală a Rutenilor din România, organizația reprezentativă a minorității rutene din România. Acest indicativ a afost folosit de-alungul colaborării, iar în anul 2020 s-a folosit indicativul special YR2ØUCRR pentru promovarea Ziua Rutenilor din România, 20 de ani de la infiintarea Uniunii Culturale a Rutenilor din Romania cât și activitatea culturală și proiectele acestei organizații. Astfel, semnalele noastre radio au făcut cunoscute valorile culturale și spirituale ale minoritătii rutene, minoritate care alături de alte minorități au participat activ la Marea Adunare Națională de la Alba Iulia de la 1 Decembrie 1918, acest lucru fiind menționat și în Rezoluțiunea Marii Adunări Naționale, dar și legăturile vechi cu majoritatea românească.',
    photos: ['assets/images/activitati/5/0.jpg', 'assets/images/activitati/5/1.jpg', 'assets/images/activitati/5/2.jpg', 'assets/images/activitati/5/3.jpg', 'assets/images/activitati/5/4.jpg', 'assets/images/activitati/5/5.jpg', 'assets/images/activitati/5/6.jpg', 'assets/images/activitati/5/7.jpg', 'assets/images/activitati/5/8.jpg']
  },
  {
    id: 4,
    title: 'YPØCD - Festivalul Cetăților Dacice',
    photos: ['assets/images/activitati/8/1.jpg', 'assets/images/activitati/8/2.jpg', 'assets/images/activitati/8/3.jpg', 'assets/images/activitati/8/4.jpg',
      'assets/images/activitati/8/5.jpg'],
    content: 'Activitatea s-a permanentizat în perspectiva promovării unor monumente istorice existente pe teritoriul județului Alba, vestigii a căror vechime datează din antichitate și care dovedesc existența dacilor pe aceste meleaguri. Numim aici cetățile dacice: Apoulon de la Piatra Craivii (Cricău), Singidava - Cugir, Căpâlna și Cetatea de Baltă. Pentru aceste evevniment s-au folosit indicativele YO1900D și YO0CD.\n' +
      'Acest proiect s-a defășurat în colaborare cu Consiliul Județean Alba.\n',
  },
  {
    id: 5,
    title: 'YR5TF - Târgul de Fete – Găina\n',
    photos: ['assets/images/activitati/7/1.jpg', 'assets/images/activitati/7/2.jpg', 'assets/images/activitati/7/3.jpg'],
    content: 'Târgul de Fete de pe Muntele Găina, cea mai cunoscută sărbătoare populară din România, se organizează de sute de ani în cea mai apropiată duminică de sărbătoarea Sfântului Ilie şi păstrează caracteristicile tradiţionale autentice acestui minunat colț de țară.\n' +
      'În trecut, locuitorii din peste 80 de sate ale Apusenilor se adunau pe platoul de pe Muntele Găina pentru a face schimb de produse. Sătenii care urcau pe munte îi duceau cu ei și pe cei tineri, pentru a socializa.\n' +
      'Acest proiect s-a defășurat în colaborare cu Consiliul Județean Alba.'
  },
];
