export const activitati = [
  {
    id: -5,
    title: 'Concursul IARU 145 MHz',
    content: 'În perioada 07 - 08 septembrie 2024, colegii noștri YO5OKM, YO5CEA și YO5DDD au participat la Concursul IARU 145MHz și Memorial YO7VS 144MHz cu indicativele de club YQ5A/P și YO5KAL/P. S-a lucrat cu transceiverul YAESU FT991A primit din partea Primăriei  Ciugud de către YO5KAL, pentru care mulțumim și pe această cale. Locația a fost Via Transilvanica din zona localității Ciugud. Mulțumim Asociației Limbenii pentru pavilion, grup electrogen și transport în KN16TB.',
    photos: ['assets/images/activitati/22/1.jpg', 'assets/images/activitati/22/2.jpg', 'assets/images/activitati/22/3.jpg', 'assets/images/activitati/22/4.jpg', 'assets/images/activitati/22/5.jpg',],
  },
  {
    id: -4,
    title: 'Finalizarea Școlii de vară 2024',
    content: 'În data de 1 septembrie a.c., la Căminul Cultural din localitatea Limba comuna Ciugud s-au finalizat cursurile Școlii de vară cu durata de două luni a secției de radioamatorism a Asociației Limbenii. S-au acordat diplome și premii participanților și diplome de mulțumire profesorilor care au prestat activitatea benevol și cu mare pasiune.\n' +
      'Ținând cont de activitatea Radioclubului Asociației Limbenii (YO5KAL) Primăria Comunei Ciugud prin domnul primar Gheorghe Damian a dotat radioclubul cu o stație radio YAESU FT991A, o sursă de alimentare, cablu coaxial și mufe pentru cablu coaxial. De asemenea a aprobat mutarea radioclubului la Căminul Cultural din Limba, unde există condiții foarte bune pentru desfășurarea activității precum și teren suficient pentru a putea fi montate diverse antene.\n' +
      ' Mulțumim pe această cale atât pentru dotare cât și pentru tot sprijinul manifestat din partea întregii comunități.\n' +
      'În continuare ne propunem să montăm atât piloni cât și antene necesare pentru participarea la viitoarele concursuri naționale și internaționale.\n',
    photos: ['assets/images/activitati/21/1.jpg', 'assets/images/activitati/21/2.jpg', 'assets/images/activitati/21/3.jpg', 'assets/images/activitati/21/4.jpg', 'assets/images/activitati/21/5.jpg', 'assets/images/activitati/21/6.jpg', 'assets/images/activitati/21/7.jpg'],
  },
  {
    id: -3,
    title: 'Campionatul național de unde ultrascurte\n',
    content: 'În perioada 17-18 august, Radioclubul Asociației Limbenii - YO5KAL a participat la Campionatul național de unde ultrascurte, din locația Via Transilvanica locația fiind deasupra Comunei Ciugud. Echipa a fost alcătuită din Popa Vasile – YO5DDD, Cristea Ștefan – YO5CEA, Molnar Ladislau – YO5OKM și din ajutoarele de nădejde Bucur Dorin  și Streza Marian Ioan. S-au efectuat legături radio cu stații din Italia, Olanda, Luxemburg, SUA, Tailanda, Indonezia, Suedia, Turcia, Germania, Israel, Elveția, Bulgaria, Spania, Croația, Grecia, Lituania, Moldova, Franța, Serbia, Polonia, Ungaia, Slovacia, Cehia, Anglia, Cipru, Ucraina și Macedonia.\n' +
      ' \t Rezultatele vor fi anunțate peste 30 de zile.\n' +
      ' Mulțumim comunității pentru ajutorul  acordat!\n',
    photos: ['assets/images/activitati/20/1.jpg', 'assets/images/activitati/20/2.jpg', 'assets/images/activitati/20/3.jpg', 'assets/images/activitati/20/4.jpg', 'assets/images/activitati/20/5.jpg', 'assets/images/activitati/20/6.jpg', 'assets/images/activitati/20/7.jpg'],
    video: 'https://www.youtube.com/embed/BFeELkXZTWI?si=fn1Xk9eP0kAF4J6b'
  },
  {
    id: -2,
    title: 'Seara pescarilor\n',
    content: 'În organizarea Asociației Limbenii și a Primăriei Ciugud, în data de 3 august a.c. a avut loc, la Limba, pe malul Mureșului la “Marina” un concurs de pescuit sportiv tradițional și un concurs de gătit la ceaun, evenimente devenite deja tradiționale și așteptate an de an de concurenți și participanți. Cu această ocazie Radioclubul Asociației Limbenii – YO5KAL, a lucrat în portabil cu indicativul YO5KAL/P, de pe malul Mureșului, de la locul desfășurării concursurilor. Legăturile au fost confirmate cu un QSL realizat special pentru acest eveniment. Pentru primii clasați au fost realizate diplome presonalizate.',
    photos: ['assets/images/activitati/19/1.jpg', 'assets/images/activitati/19/2.jpg', 'assets/images/activitati/19/3.jpg', 'assets/images/activitati/19/4.jpg', 'assets/images/activitati/19/5.jpg', 'assets/images/activitati/19/6.jpg', 'assets/images/activitati/19/7.jpg', 'assets/images/activitati/19/8.jpg', 'assets/images/activitati/19/9.jpg', 'assets/images/activitati/19/10.jpg', 'assets/images/activitati/19/11.jpg', 'assets/images/activitati/19/12.jpg', 'assets/images/activitati/19/13.jpg', 'assets/images/activitati/19/14.jpg', 'assets/images/activitati/19/15.jpg', 'assets/images/activitati/19/16.jpg'],
    pdf: [
      {
        content: 'Vizualizare QSL:',
        link: 'assets/images/activitati/19/qsl.pdf',
      }
    ],
    video: 'https://www.youtube.com/embed/0nKagxY8f1Y?si=dSgvCr52oc9I51iE'
  },
  {
    id: -1,
    title: 'Școala de vară\n',
    content: 'După înființarea în data de 29 mai 2024 a Radioclubului ASOCAȚIA LIMBENII, o provocare a fost participarea alături de membrii ai Asociației Limbenii la Școala de vară a acestei asociații. Aceasta își propune organizarea de-alungul verii a unor activități pentru dezvoltarea personală a copiilor din comuna Ciugud și nu numai. Astfel prin efortul colegilor noștri Adrian Stoicescu YO5BFJ, Popa Vasile YO5DDD, Cristea Ștefan YO5CEA, Utea Dan YO5GHA și Molnar Ladislau YO5OKM s-a reușit organizarea unor cursuri de electronica și radioamatorism. Semnalele au fost pozitive, astfel că sperăm că cu ajutorul și sprijinul administrației locale să putem continua, astfel cât mai mulți copii să participe la aceste cursuri.',
    photos: ['assets/images/activitati/18/1.jpg', 'assets/images/activitati/18/2.jpg', 'assets/images/activitati/18/3.jpg', 'assets/images/activitati/18/4.jpg'],
    video: 'https://www.youtube.com/embed/AK7AXg55f_c?si=gJEWQ-a2w_sFG7_A',
  },
  {
    id: 0,
    title: 'Adunarea Generala 2023 \n',
    content: ' Duminică, 02.07.2023, s-a desfășurat adunarea generală a asociației noastre, care a avut următoarea ordine de zi: raportul Consiliului Director asupra activității desfășurate pe parcursul anului 2022, raportul financiar pe anul 2022, calendarul competițional propus pentru 2023, scoaterea din funcțiune a unor bunuri cu termenul de funcționare depășit, discuții libere.\n' +
      'Membrii asociației au apreciat activitatea desfășurată și au făcut numeroase propuneri. Astfel s-a propus continuarea unor concursuri consacrate precum: 1 Decembrie - Ziua Națională, Lucian Blaga și continuarea colaborării cu Uniunea Culturală a Rutenilor din România. De asemenea a reieșit necesitatea creării unui soft de arbitraj dedicat concursului Cupa 1 Decembrie.\n' +
      'Discuțiile au relevat și faptul că nu am rezolvat problema instalării repetorului, propunându-se continuarea căutarii unei locații adecvate.\n' +
      'Adunarea a debutat cu un eveniment deosebit prin care, U.C.R.R. a acordat diplome și premii celor care au participat activ la activitatile organizate cu prilejul zilei de 5 Martie – Ziua Rutenilor din România, și anume: YO5IB, YO5OHT, YO5OKM, YO5DDD, YO5YM și YO5BFJ.\n',
    photos: ['assets/images/activitati/17/0.jpg', 'assets/images/activitati/17/1.jpg', 'assets/images/activitati/17/2.jpg', 'assets/images/activitati/17/3.jpg', 'assets/images/activitati/17/4.jpg'],
  },
  {
    id: 1,
    title: 'Adunarea Generala 2022 \n',
    content: 'În ziua de 26 iunie 2022 a avut loc Adunarea Generală a Asociației la care au fost prezenți toți reprezentanții asociațiilor membre si 12 radioamatori membri ai asociației.\n' +
      'La ordinea de zi s-au dezbătut raportul de activitate pe anul 2021, raportul financiar, calendarul sportiv și alte probleme ridicate de membrii asociației: locație repetor și evenimentul Centenerul Incoronării.\n' +
      'La discuții au fost subliniate necesitatea instalării unui repetor în zona județului Alba, cel existent fiind dezafectat prin pierderea locației unde era instalat. Au fost propuse două locații, Râmeț și Ciugud și au fost stabilite două echipe care să analizeze aceste locații și care să vină cu soluții concrete de realizare.\n' +
      'De asemenea s-a dicutat mult privind Centenarul Încoronării Regelui Ferdinand, ca fiind un eveniment istoric important și pentru care trebuie să organizăm o acțiune specifică, așa cum am făcut și pentru alte evenimente.\n' +
      '\tS-a stabilit că în afară de activitatea diurnă pentru marcarea evenimentului să se organizeze un concurs dedicat evenimentului. Acest concurs va fi și un experiment pentru alte activități similare viitoare. El va fi organizat și se va desfășura în data de 15 octombrie, simultan, în toate cele trei moduri de lucru principale: telegrafie, fonie și digital. Vor fi tipărite QSL-uri și se vor acorda diplome aniversare ce se vor putea descarca de pe pagina noastră www.albadxradio.ro.',
    photos: ['assets/images/activitati/15/1.jpeg', 'assets/images/activitati/15/2.jpeg', 'assets/images/activitati/15/3.jpeg', 'assets/images/activitati/15/4.jpeg', 'assets/images/activitati/15/5.jpeg' ],
  },
  {
    id: 2,
    title: 'Adunarea Generala 2021 \n',
    content: 'După un an și jumătate, iată că a fost posibilă o nouă adunare a membrilor A.J.R. Alba, prilej de mare satisfacție pentru noi toți. Bucuria revederii a fost emoționantă și cu greu s-a putut trece la desfășurarea propriu zisă a adunării.\n' +
      'Consiliul Director a raportat îndeplinirea integrală a sarcinilor stabilite la ultima adunare, respectiv: modificarea și legalizarea Statutului asociației, organizarea și desfășurarea activităților dedicate Zilei Naționale a României și colaborarea cu U.C.R.R. Activitatea radio a fost bună, traficul radio a crescut și mulți dintre membrii asociației noastre au participat la concursuri naționale și internaționale. Din punct de vedere financiar nu au fost probleme, Consiliul Director limitând cheltuielile la strictul necesar.\n' +
      'La finalul raportului a fost prezentat site-ul asociației, realizat de o echipă condusa de YO5PUR, urmând ca acesta să poarte numele www.albadxradio.ro și să se găseasca o variantă acceptabila de hosting.\n' +
      'Pentru anul urmator s-au stabilit urmatoarele direcții principale de activitate: continuarea activitșților dedicate Zilei Naționale a României, continuarea colaborării cu U.C.R.R., iar din punct de vedere tehnic, stabilirea unei locații și instalarea unui repetor vocal în colaborare cu AE1AA - Ovidiu, originar din Alba Iulia.\n',
    photos: ['assets/images/activitati/13/1.jpg', 'assets/images/activitati/13/2.jpg', 'assets/images/activitati/13/3.jpg', 'assets/images/activitati/13/4.jpg', 'assets/images/activitati/13/5.jpg', 'assets/images/activitati/13/6.jpg'],
  },
  {
    id: 3,
    title: 'Adunarea Generală a AJR Alba 2019\n',
    content: 'În data de 17 noiembrie 2019, membrii Asociației Județene de Radioamatorism Alba s-au întâlnit în Adunarea Generală, pentru discutarea unor modificări și completări ale satutului asociației. Au fost prezenti toți reprezentanții cluburilor componente și majoritatea radioamatorilor asociației. S-au discutat mai multe variante de lucru în elaborarea noului statut. Consiliul Director a fost însărcinat să finalizeze proiectul, urmând ca deciziile finale să fie luate la următoarea adunare. Un alt punct de pe ordinea de zi a fost organizarea și desfășurarea concursului Cupa 1 Decembrie, ediția 2019, activitate permanentizată a asociației noastre. De asemenea a fost discutată si aprobată, de principiu, continuarea colaborarii cu Uniunea Culturală a Rutenilor din România, urmând ca finalizarea contractului să fie efectuată de conducerile celor două entități în asa fel, încat, în jurul datei de 1 martie 2020, acest proiect să fie derulat.\n' +
      'La punctul patru a fost prezentat bilanțul contabil pe anul 2019 și s-a discutat și aprobat proiectul de buget pentru anul 2020.\n',
    photos: ['assets/images/activitati/3/1.jpg', 'assets/images/activitati/3/2.jpg', 'assets/images/activitati/3/3.jpg','assets/images/activitati/3/4.jpg'],
    sign: 'Adrian Stoicescu – YO5BFJ',
  },
  {
    id: 4,
    title: 'Campionatul național de radio orientare individual\n',
    content: '\tÎn perioada 8 – 11 august 2019, am avut plăcerea de a fi parteneri în organizarea Campionatului național de radio orientare individual, la Alba Iulia, competiție aflată pe agenda Federației Române de Radioamatorism. Acest eveniment sportiv a fost important, în special în definirea pregătirii sportivilor participanți, această competiție fiind ultima înaintea participării la Compionatul European de Radiogoniometrie, de la Rogla, din Slovenia, în perioada 1 – 8 septembrie 2019. A fost un prilej pentru noi membrii ai AJR Alba de a dovedi că suntem parteneri de nădejde și că suntem mereu preocupați de destinele radioamatorismului, atât în județul Alba cât și în țară. Am reîntâlnit prieteni dragi între oficialități dl. Marius Mitruț YOCZW – secretar general al FRR, dl. Marcu Adrian Florin YO7LOI – președintele Comisiei Centrale de Radioorientare a FRR dar și printre concurenții mai mult sau mai puțin tineri. Am reușit, într-un termen relativ scurt, să pregătim participanților și oficialităților cadouri din partea AJR Alba, care să le aducă aminte de vizita în Alba Iulia, acestea fiind înmânate în cadrul festiv al premierii de către domnii Adrian Stoicescu YO5BFJ și Vasile Popa YO5DDD.',
    photos: ['assets/images/activitati/4/1.jpg', 'assets/images/activitati/4/2.jpg', 'assets/images/activitati/4/3.jpg','assets/images/activitati/4/4.jpg',
      'assets/images/activitati/4/5.jpg','assets/images/activitati/4/6.jpg','assets/images/activitati/4/7.jpg'],
    sign: 'Francisc Gal YO5PUR',
  },
  {
    id: 5,
    title: 'Simpo YO 2008 – Alba Iulia',
    photos: ['assets/images/activitati/9/0.jpg', 'assets/images/activitati/9/1.jpg', 'assets/images/activitati/9/2.jpg', 'assets/images/activitati/9/3.jpg','assets/images/activitati/9/4.jpg',
      'assets/images/activitati/9/6.jpg','assets/images/activitati/9/7.jpg','assets/images/activitati/9/8.jpg','assets/images/activitati/9/9.jpg',
      'assets/images/activitati/9/10.jpg','assets/images/activitati/9/11.jpg','assets/images/activitati/9/12.jpg','assets/images/activitati/9/13.jpg','assets/images/activitati/9/14.jpg',
      'assets/images/activitati/9/15.jpg','assets/images/activitati/9/16.jpg'],
    content: 'Asociația noastră a avut onoarea ca, în perioada 1-3 august 2008, să gazduiască Simpozionul național al radioamatorilor români, activitate cu o foarte mare încarcatură emoțională pentru participanți și o adevărată provocare pentru organizatori. Și de aceasta dată am fost sprijiniți de Consiliul Județean Alba și de Direcția pentru Sport a județului. Locația desfășurarii evenimentului a fost în “Cetatea Alba Carolina”, în clădirea Cercului Militar Alba, loc cu o încărcătură istorică și care a impresionat profund pe toți participanții, mai ales că majoritatea covârșitoare a acestora nu au mai “călcat pe atâta istorie“ niciodată. Am avut plăcerea să avem invitați și participanți la aceast eveniment personalități de excepție a vieții radioamatoricești și publice la nivel central și local, printre care doresc să-i amintesc pe Vasile Ciobănița YO3APG – Secretarul general al Federației Române de Radioamatorism și Ioan Dumitrel Președintele Consiliului Județean Alba.\n' +
      'Activitațile desfășurate în cadrul simpozionului s-au axat în mod special pe prezentarea unor referate privind activitatea de radioamator, modalități de operare, moduri noi de lucru și noutăți tehnice. În același timp s-a desfasurat campionatul național de creație tehnică, unde au fost prezentate multe realizări tehnice deosebite.\n' +
      'În timpul liber participantii au fost invitați sa viziteze Cetatea Alba Iulia, iar cei care au dorit au făcut o vizită la Mănăstirea Râmeț.\n',
  },
];
