import { Component, OnInit } from '@angular/core';
import {membrii} from './constants';

@Component({
  selector: 'app-membrii',
  templateUrl: './membrii.component.html',
  styleUrls: ['./membrii.component.scss']
})
export class MembriiComponent implements OnInit {
  memberListSorted = membrii.sort((a, b) => (a.id > b.id) ? 1 : -1);
  memberDeadList = [
    {
      id: 'YO5CBN',
      name: 'Streza Ioan',
      operator: 'Nelu',
      authorizationClass: 'II',
      authorizationYear: '1980',
      email: '',
      phone: '',
    },
    {
      id: 'YO5BET',
      name: 'Canciu Emil',
      operator: '',
      authorizationClass: '',
      authorizationYear: '',
      email: '',
      phone: '',
    },
  ];
  memberList = [...this.memberListSorted];

  constructor() { }

  ngOnInit() {
  }

}
