import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.scss']
})
export class PhotoAlbumComponent {

  constructor() {}

  public slides =
    [
      'assets/images/albumPhoto/1.jpg',
      'assets/images/albumPhoto/2.jpg',
      'assets/images/albumPhoto/3.jpg',
      'assets/images/albumPhoto/4.jpg',
      'assets/images/albumPhoto/5.jpg',
      'assets/images/albumPhoto/6.jpg',
      'assets/images/albumPhoto/7.jpg',
      'assets/images/albumPhoto/8.jpg',
      'assets/images/albumPhoto/9.jpg',
      'assets/images/albumPhoto/10.jpg',
      'assets/images/albumPhoto/11.jpg',
      'assets/images/albumPhoto/12.jpg',
      'assets/images/albumPhoto/13.jpg',
      'assets/images/albumPhoto/14.jpg',
      'assets/images/albumPhoto/15.jpg',
      'assets/images/albumPhoto/16.jpg',
      'assets/images/albumPhoto/17.jpg',
      'assets/images/albumPhoto/18.jpg',
      'assets/images/albumPhoto/19.jpg',
      'assets/images/albumPhoto/20.jpg',
    ];

}
