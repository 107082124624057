import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { InfocoronaComponent } from './info-corona/infocorona.component';
import { ClubsComponent } from './clubs/clubs.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MembriiComponent } from './membrii/membrii.component';
import { ConcursuriComponent } from './concursuri/concursuri.component';
import { CentenarComponent } from './centenar/centenar.component';
import { ActivitatiComponent } from './activitati/activitati.component';
import { FileDeIstorieComponent } from './file-de-istorie/file-de-istorie.component';
import { StadionulDinEterComponent } from './stadionul-din-eter/stadionul-din-eter.component';
import { ContactComponent } from './contact/contact.component';
import { PhotoAlbumComponent } from './photo-album/photo-album.component';
import {SliderModule} from "angular-image-slider";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { PrezentaComponent } from './prezenta/prezenta.component';
import { LinksComponent } from './links/links.component';
import {IndicativeComponent} from './indicative/indicative.component';
import {Teste} from './teste/teste';
import { GeneratorComponent } from './generator/generator.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    AboutusComponent,
    ClubsComponent,
    NotFoundComponent,
    MembriiComponent,
    ConcursuriComponent,
    CentenarComponent,
    ActivitatiComponent,
    FileDeIstorieComponent,
    StadionulDinEterComponent,
    ContactComponent,
    PhotoAlbumComponent,
    PrezentaComponent,
    LinksComponent,
    InfocoronaComponent,
    IndicativeComponent,
    Teste,
    GeneratorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SliderModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
