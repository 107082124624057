import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-centenar',
  templateUrl: './centenar.component.html',
  styleUrls: ['./centenar.component.scss']
})
export class CentenarComponent {

  public slidesCentenar =
    [
      "assets/images/centenarPhoto/DSC_0061.JPG", "assets/images/centenarPhoto/DSC_0026.JPG",
      "assets/images/centenarPhoto/DSC_0012.JPG", "assets/images/centenarPhoto/DSC_0040.JPG",
    ];

  public sliderDiploma =
    [
      "assets/images/diplomeCentenar/0.jpg", "assets/images/diplomeCentenar/1.jpg",
      "assets/images/diplomeCentenar/2.jpg", "assets/images/diplomeCentenar/3.jpg",
      "assets/images/diplomeCentenar/4.jpg", "assets/images/diplomeCentenar/5.jpg",
      "assets/images/diplomeCentenar/6.jpg", "assets/images/diplomeCentenar/7.jpg",
      "assets/images/diplomeCentenar/8.jpg"
    ];

  constructor() {
  }
}

