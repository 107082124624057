import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prezenta',
  templateUrl: './prezenta.component.html',
  styleUrls: ['./prezenta.component.scss']
})
export class PrezentaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
