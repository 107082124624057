import { Component, OnInit } from '@angular/core';
import {activitati} from './activitati_data';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-activitati',
  templateUrl: './activitati.component.html',
  styleUrls: ['./activitati.component.scss']
})
export class ActivitatiComponent implements OnInit {

  activities = activitati;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
