export const activitati = [
  {
    id: 0,
    title: 'Download programe log',
    content: 'De pe aceasta pagina puteti descarca programele de log.',
    photos: [],
    link1: 'assets/program/Log_ON.zip',
    link2: 'assets/program/LogOFF.zip',
    sign: null,
  },
];
